import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Hackerrank – Queues: A Tale of Two Stacks",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:58:20.000Z",
  "url": "/2018/02/hackerrank-queues-a-tale-of-two-stacks/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["Exercises"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Exercise:`}</strong>{` `}{`Queues: A Tale of Two Stacks`}</p>
    <p><strong parentName="p">{`Site:`}</strong>{` `}{`Hackerank`}</p>
    <p><strong parentName="p">{`Link:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://www.hackerrank.com/challenges/ctci-queue-using-two-stacks/problem"
      }}>{`hackerrank`}</a></p>
    <p><strong parentName="p">{`Language:`}</strong>{` `}{`Python`}</p>
    <p>{`Just got back to my daily programming exercises and start with this nice question on implementing a queue with 2 stacks.`}</p>
    <p>{`So the requirements are simply having a Queue with these methods:`}</p>
    <ul>
      <li parentName="ul">{`put(value) //add another element to the queue`}</li>
      <li parentName="ul">{`pop() //returns the first element`}</li>
      <li parentName="ul">{`peak() //previews the first element`}</li>
    </ul>
    <p>{`Of course, I`}{`’`}{`m not suppose to use the built-in queue for any language I use, and Im suppose to do it with 2 Stacks.`}</p>
    <p>{`I love changing languages while solving all those exercises between javascript, python, PHP, and ruby but this time I used python.`}</p>
    <h6>{`Let`}{`’`}{`s start {#letsstart}`}</h6>
    <p>{`First I need to have myself a `}{`“`}{`Stack`}{`”`}{` object, so I create one quickly:`}</p>
    <pre><code parentName="pre" {...{}}>{`class MyStack(object):  
    def __init__(self):
        self.holder = []

    def getAll(self):
        return self.holder

    def size(self):
        return len(self.holder)

    def pop(self):
        return self.holder.pop(0)

    def put(self, value):
        self.holder.append(value)
`}</code></pre>
    <p>{`this simply uses python list object for storage, and I push to the end and pop from the start.`}</p>
    <p>{`Now let`}{`’`}{`s create our Queue`}</p>
    <pre><code parentName="pre" {...{}}>{`class MyQueue(object):  
    def __init__(self):
        self.pushes = MyStack()
        self.pops = MyStack()

    def peek(self):
        pops = self.pops.getAll()
        if (len(pops) > 0):
            return pops[0]
        pushes = self.pushes.getAll()
        if (len(pushes) > 0):
            return pushes[0]
        return None

    def pop(self):
        if self.pops.size() == 0:
            for  i in xrange(self.pushes.size()):
                self.pops.put(self.pushes.pop())

        return self.pops.pop()

    def put(self, value):
        self.pushes.put(value)
`}</code></pre>
    <p><strong parentName="p">{`The idea:`}</strong>{` `}{`I have one stack for pushes and one stack for pops. All pushes go to the first and when I pop I first check for the pops stack if it`}{`’`}{`s not empty I pop from it if its empty I fill it with the elements from the pushes stack.`}</p>
    <p><strong parentName="p">{`put:`}</strong>{` `}{`nothing much here, just adding a value to my `}{`“`}{`pushes`}{`”`}{` stack.`}</p>
    <p><strong parentName="p">{`pop:`}</strong>{` `}{`check if something in the pops stack if so take it out. If not then empty the pushes stack into the pops stack and then pop it from there.`}</p>
    <p><strong parentName="p">{`peek:`}</strong>{` `}{`I simply return the first element of pops if it exists and if not the first from pushes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      